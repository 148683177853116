<template>
  <div class="appointment-detail" v-if="detail.orderItems.length">
    <div class="appointment-card" >
      <div class="appointment-info">
        <div class="appointment-info-title flex">
          <span style="flex: 1">{{detail.orderItems[0].memberName}}</span>
          <span class="appointment-status" >
            {{detail.orderItems[0].status == '6' ? '已完成' : '待体检'}}
          </span>
        </div>
        <p>医院：{{detail.hospitalName}}</p>
        <p>套餐：{{detail.orderItems[0].productName}}</p>
      </div>
      <div class="appointmnet-state">
        <div class="state-img">
          <img src="@/assets/icon_img_wancheng@2x.png" alt="">
        </div>
        <p>{{detail.orderStatus}}</p>
        <div>
          <span>
            <span class="mr12">到检时间</span>
            <span>{{detail.orderItems[0].appointmentDate}}</span>
          </span>
          <van-button type="primary" size="small" round 
          v-if="detail.status !== 6"
          @click="$router.push(`/edit-appointment/${id}?hospitalProductId=${detail.orderItems[0].hospitalProductId}`)">体检改约</van-button>
        </div>
      </div>
      <div class="appointment-step">
        <div class="appointment-step-item active">
          <div class="appointment-step-item-title">
            等待体检
          </div>
          <div class="appointment-step-item-content">
            <p>注意休息，饮食清淡</p>
            <van-button round size="small" @click="showNoticeDialog = true">注意事项</van-button>
          </div>
        </div>
        <div class="appointment-step-item">
          <div class="appointment-step-item-title">
            体检日，请按时到达医院体检
          </div>
           <div class="appointment-step-item-content">
            <p>地点：{{detail.address}}</p>
          </div>
        </div>
        <div class="appointment-step-item">
          <div class="appointment-step-item-title">
            体检报告获取
          </div>
           <div class="appointment-step-item-content border-none">
            <p>体检结束后，请根据医院指引获取您的电子报告或纸质报告！</p>
          </div>
        </div>
      </div>
    </div>
    <div class="appointment-other-info">
      <div class="other-info-title">
        <span style="flex: 1" class="bold">电子发票</span>
        <van-button round size="small"
        @click="showDialog = true"
        v-if="['6', '8'].includes(detail.orderItems[0].status)"
        :disabled="detail.orderItems[0].billStatus">{{detail.orderItems[0].billStatus ? '已申请': '开票申请'}}</van-button>
      </div>
    </div>
    <div class="appointment-other-info">
      <div class="other-info-title">
        <span style="flex: 1">体检详情</span>
      </div>
      <formBox :formData='detail' :formList="formList" mode='view' />
    </div>
    <div class="appointment-other-info">
      <div class="other-info-title">
        <span style="flex: 1">体检医院</span>
      </div>
      <p class="info-sub-title">{{detail.hospitalName}}</p>
      <p class="info-content">{{detail.address}}</p>
    </div>
    <div class="appointment-other-info">
      <div class="other-info-title">
        <span style="flex: 1">体检套餐</span>
      </div>
      <p class="info-sub-title">套餐项目</p>
      <p class="info-content" v-if="detail.orderItems.length">{{detail.orderItems[0].productJson.projectItemsName}}</p>
    </div>
    <van-popup v-model="showDialog" position="center" round closeable :close-on-click-overlay="false">
      <div class="popup-center">
        <div class="popup-title">
          开票申请
        </div>
        <div class="popup-content">
          <div class="form">
            <van-field label="发票抬头：" v-model="ticket.title" />
            <van-field label="税号：" v-model="ticket.taxId" />
            <van-field label="电子邮箱：" v-model="ticket.email" />
          </div>
          <div class="popup-btns">
            <van-button round @click="showDialog = false">取消</van-button>
            <van-button type="primary" round @click="issueAnInvoice">提交</van-button>
          </div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showNoticeDialog" position="center" round>
      <div class="popup-center notice-popup">
        <div class="img">
          <img src="@/assets/icon_img_doctor@2x.png" alt="">
        </div>
        <div class="popup-title">
          注意事项
        </div>
        <p>1.体检前3天不要大量饮酒、不要进食动物内脏、清淡饮食，拒绝暴饮暴食、少吃高脂、高糖高蛋白食物，避免熬夜或短期剧烈运动。</p>
        <p>2．空腹8小时以上，体检前一天晚十点后，不进食任何食物，可少量饮水，直至空腹项目（抽血、肝胆B超、胃肠道检查）检查完成。</p>
        <p>3.糖尿病、高血压等心血管慢性疾病者，应按时吃药，少量饮水送服即可，避免因停药而发生意外。</p>
        <p>4.体检当天衣着宽松，不建议佩戴隐形眼镜、首饰等。</p>
         <div class="popup-btns">
            <van-button type="primary" round @click="showNoticeDialog = false">知道了</van-button>
          </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { IssueAnInvoice, GetOrderItem } from '@/api'
import formBox from '@/components/formBox/index'
export default {
  name: 'appointment-detail',
  components: {
    formBox
  },
  computed: {

  },
  data () {
    return {
      id: '',
      detail: {
        memberName: '',
        certificateOfType: '',
        certificateNo: '',
        sex: '',
        mobile: '',
        maritalState: ''
      },
      formList: [
        {
          type: 'Input',
          label: '体检人',
          prop: 'memberName',
          placeholder: '请输入体检人的真实姓名'
        },
        {
          type: 'Select',
          label: '证件类型',
          prop: 'certificateOfType',
          headerName: '证件类型',
          options: [
            {
              label: '身份证',
              value: '1'
            },
            {
              label: '护照',
              value: '2'
            }
          ]
        },
        {
          type: 'Input',
          label: '证件号码',
          inputType: 'number',
          prop: 'certificateNo',
          placeholder: '请输入证件号'
        },
        // {
        //   type: 'Time',
        //   label: '出生日期',
        //   prop: 'd',
        //   show: (formData) => formData.certificateOfType === '2',
        //   placeholder: '请选择出生日期'
        // },
        {
          type: 'RadioGroup',
          label: '性别',
          show: (formData) => formData.certificateOfType === '2',
          prop: 'sex',
          options: [{
            label: '男',
            value: 1
          }, {
            label: '女',
            value: 2
          }],
        },
        {
          type: 'Input',
          label: '手机号',
          inputType: 'number',
          prop: 'mobile',
          placeholder: '请输入手机号'
        },
        {
          type: 'RadioGroup',
          label: '婚姻状况',
          prop: 'maritalState',
          options: [{
            label: '已婚',
            value: 1
          }, {
            label: '未婚',
            value: 2
          }],
        },
      ],
      ticket: {},
      showNoticeDialog: false,
      showDialog: false
    }
  },
  created () {
    this.$setTitle('预约详情')
    this.$store.commit('setTitle', '预约详情')
    this.id = this.$route.params.id
    this.getDetail()
  },
  methods: {
    async getDetail () {
      let data = await GetOrderItem({OrderNo: this.id})
      this.detail = data
      if (this.detail.orderItems.length) {
         this.detail.orderItems = this.detail.orderItems.map(item => {
           item.productJson = item.productJson ? JSON.parse(item.productJson) : {}
          if (item.productJson.projectItems && item.productJson.projectItems.length) {
            item.productJson.projectItemsName = item.productJson.projectItems.map(_item => _item.projectName).join(',')
          }
           return item
         })
         this.detail.memberName = this.detail.orderItems[0].memberName
         this.detail.certificateNo = this.detail.orderItems[0].certificateNo
         this.detail.mobile = this.detail.orderItems[0].mobile
          this.detail.sex = this.detail.orderItems[0].sex == '1' ? '男' : '女'
           this.detail.maritalState = this.detail.orderItems[0].maritalState == '1' ? '已婚' : '未婚'
           this.detail.certificateOfType = this.detail.orderItems[0].certificateOfType == '1' ? '身份证' : '护照'
      }
       
      console.log(this.detail)
      
    },
    async issueAnInvoice () {
      const params = {
        ...ticket,
        orderDetlId: this.detail.orderDetlId
      }
      let data = await IssueAnInvoice(params)
      this.showDialog = false
    }
  }
}
</script>

<style lang="scss">
.appointment-detail{
  flex: 1;
  background-color: #fff;
  overflow-y: auto;
  padding: 10px 15px;
  -webkit-overflow-scrolling: touch;
  .appointment-card{
    padding: 11px 12px;
    background-color: #fff;
    box-shadow: 0px 0px 35px rgba(215, 215, 215, 0.5);
    margin-bottom: 20px;
    .appointment-info{
      border-radius: 10px;
      overflow:hidden;
      height: 112px;
      background: url('../assets/icon_img_bj@2x.png') no-repeat top left;
      background-size: 100%;
      padding: 16px 0 16px 18px;
      color: #fff;
      .appointment-info-title{
        font-size: 18px;
        align-items: center;
        margin-bottom: 6px;
        .appointment-status{
          width: 60px;
          height: 20px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          background: #FFD40A;
          font-size: 12px;
          text-align: center;
        }
      }
      p{
        line-height: 30px;
        font-size: 14px;
      }
    }
    .appointmnet-state{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #131828;
      margin-bottom: 10px;
      .state-img{
        width: 70px;
        margin: 10px;
      }
      p{
        margin-bottom: 20px;
        font-weight: 800;
      }
      >div{
        width: 100%;
        display: flex;
        align-items: center;
        >span{
          flex: 1;
          text-align: center;
          span{
            font-weight: bold;
          }
        }
        .van-button--primary{
          background: #313237;
          border: 1px solid #313237;
          color: #FFD40A;
          font-size: 13px;
        }
      }
    }
    .appointment-step{
      .appointment-step-item{
        margin-bottom: 10px;
        .appointment-step-item-title{
          color: #131828;
          font-size: 15px;
          font-weight: bold;
          line-height: 18px;
          position: relative;
          padding-left: 27px;
          margin-bottom: 8px;
          &::before{
            content: '';
            display: inline-block;
            width: 18px;
            height: 18px;
            border-radius: 9px;
            border: 4px #CED2E0 solid;
            vertical-align: text-top;
            position: absolute;
            left: 0;
          }
        }
        .appointment-step-item-content{
          padding-left: 17px;
          padding-right: 20px;
          font-size: 14px;
          color: #9E9DA3;
          display: flex;
          border-left: 2px #EDEDED solid;
          margin-left: 9px;
          align-items: center;
          &.border-none{
            border-left: 2px transparent solid;
          }
          p{
            flex: 1;
          }
        }
      }
    }
  }
  .van-button--default{
    border-color: #6E7886;
  }
  .appointment-other-info{
    margin-bottom: 20px;
    .other-info-title{
      font-size: 15px;
      color: #131828;
      font-weight: bold;
      display: flex;
      align-items: center;
      span{
        font-weight: bold;
      }
      &::before{
        content: '';
        display: inline-block;
        width: 5px;
        height: 5px;
        background: #131828;
        margin-right: 9px;
      }
    }
    .info-sub-title{
      font-size: inherit;
      font-size: 15px;
      color: #131828;
      margin: 14px 0 4px;
    }
    .info-content{
      color: #9E9DA3;
      line-height: 22px;
    }
    .van-cell{
      padding: 10px 7px;
    }
  }
  .form{
    margin-bottom: 140px;
  }
  .van-popup--center.van-popup--round{
    overflow: unset;
  }
  .notice-popup{
    position: relative;
    .img{
      width: 97px;
      position: absolute;
      right: 27px;
      top: -37px;
    }
    p{
      font-size: 13px;
      color: #6E7886;
      margin-bottom: 24px;
      line-height: 1.6;
    }
    .van-button--primary{
      width: 60%;
      flex: unset;
      box-shadow: 0 10px 10px rgba(0, 196, 179, .2);
      margin: 40px 0 20px;
    }
  }
}
</style>